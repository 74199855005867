import { render, staticRenderFns } from "./ProductClass.vue?vue&type=template&id=254a8caa&scoped=true&"
import script from "./ProductClass.vue?vue&type=script&lang=js&"
export * from "./ProductClass.vue?vue&type=script&lang=js&"
import style0 from "./ProductClass.vue?vue&type=style&index=0&id=254a8caa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254a8caa",
  null
  
)

export default component.exports